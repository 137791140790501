import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  isOpen: state.modal.cta.isOpen,
  title: state.modal.cta.title,
  description: state.modal.cta.description,
  isLoading: state.modal.cta.isLoading,
})

const mapDispatch = (dispatch: any) => ({
  onSubmit: () => dispatch(actions.modal.fetchSubmit()),
  onCancel: () => dispatch(actions.modal.closeCta())
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
