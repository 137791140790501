import { navigate } from "gatsby"
import { connect, ConnectedProps } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  isAuthenticated: state.auth.isConnected,
  user: state.auth.user,
})

const mapDispatch = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  onClose: () => window?.history.back(),
  onGo: (to: string) => {
    window?.history.back()
    setTimeout(() => navigate(to), 300)
  },
  onLogout: () => {
    dispatch(
      actions.modal.openCta({
        onSubmit: () => dispatch(actions.auth.fetchLogout()),
        title: "Voulez-vous vous déconnecter ?",
        description:
          "Si vous vous déconnectez, vous ne pourrez plus mettre de j'aime ni publier de haïkus.",
      })
    )
    
    window?.history.back()
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
