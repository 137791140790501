import React, { useEffect, useState } from "react"
import { Transition } from "@headlessui/react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { connector, ContainerProps } from "./containers/Navigation.container"
import Logo from "../../assets/logo/logo.svg"

import { Drawer } from "../Drawer/Drawer"

import { CTA } from "../CTA/CTA"
import { SeoLink } from "../SeoLink/SeoLink"

const Item = ({ title, to, description, icon }) => (
  <Link
    to={to}
    className="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
  >
    <svg
      className="flex-shrink-0 w-6 h-6 text-gray-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      {icon}
    </svg>
    <div className="pl-4">
      <p className="text-base font-medium text-gray-900">{title}</p>
      <p className="mt-1 text-sm text-gray-600">{description}</p>
    </div>
  </Link>
)

export type NavigationProps = {
  onOpen?: Function
  onLogout?: Function
  isAuthenticated: boolean
  userId: string
  showLinksDirectlyForSeo?: boolean
}

export const NavigationWrapper: React.FC<NavigationProps> = ({
  onOpen,
  onLogout,
  isAuthenticated,
  userId,
  showLinksDirectlyForSeo,
}) => {
  const [isBlogOpen, setBlogOpen] = useState(false)
  const location = useLocation()
  const from = location.pathname

  useEffect(() => {
    const fn = () => {
      if (isBlogOpen) setBlogOpen(false)
    }

    window.addEventListener("click", fn)

    return () => {
      window.removeEventListener("click", fn)
    }
  }, [isBlogOpen])

  return (
    <>
      <div className="absolute top-0 left-0 z-10 w-full">
        <div className="relative">
          <div className="px-4 mx-auto max-w-7xl md:px-6">
            <div className="flex items-center py-6 lg:space-x-10">
              <div className="flex">
                <SeoLink isAsync={!showLinksDirectlyForSeo} to="/">
                  <span className="sr-only">Le Temple du Haïku</span>
                  <img
                    className="w-auto h-8 md:h-12"
                    src={Logo}
                    alt="Logo du Temple"
                  />
                </SeoLink>
              </div>
              <div className="ml-auto -my-2 -mr-2 lg:hidden">
                <button
                  type="button"
                  onClick={() => onOpen()}
                  className="inline-flex items-center justify-center p-2 text-gray-600 rounded-md hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900"
                >
                  <span className="sr-only">Ouvrir le menu</span>

                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <nav className="hidden space-x-10 lg:flex">
                <SeoLink
                  isAsync={!showLinksDirectlyForSeo}
                  to="/"
                  className="text-base font-medium text-gray-600 hover:text-gray-900"
                >
                  Découvrir
                </SeoLink>
                <SeoLink
                  isAsync={!showLinksDirectlyForSeo}
                  to="/ecrire/"
                  className="text-base font-medium text-gray-600 hover:text-gray-900"
                >
                  Écrire
                </SeoLink>

                <a
                  href="https://www.temple-du-haiku.fr/exemples-de-haiku/"
                  className="text-base font-medium text-gray-600 hover:text-gray-900"
                >
                  Trouver l'inspiration
                </a>

                {isAuthenticated && (
                  <SeoLink
                    isAsync={!showLinksDirectlyForSeo}
                    to={`/auteurs/${userId}/`}
                    className="text-base font-medium text-gray-600 hover:text-gray-900"
                  >
                    Ma page
                  </SeoLink>
                )}
              </nav>
              <div className="items-center justify-end hidden lg:flex lg:flex-1 lg:w-0">
                {!isAuthenticated && (
                  <>
                    <SeoLink
                      isAsync={!showLinksDirectlyForSeo}
                      to="/se-connecter/"
                      state={{ from }}
                      className="px-4 text-base font-medium text-gray-600 whitespace-nowrap hover:text-gray-900"
                    >
                      Se connecter
                    </SeoLink>
                    <span className="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                      <SeoLink
                        isAsync={!showLinksDirectlyForSeo}
                        to="/creer-un-compte/"
                        state={{ from }}
                        className="inline-flex items-center px-4 py-2 text-base font-medium text-gray-600 bg-white border border-transparent rounded-md hover:bg-gray-50"
                      >
                        Créer un compte
                      </SeoLink>
                    </span>
                  </>
                )}

                {isAuthenticated && (
                  <span
                    onClick={() => onLogout()}
                    className="px-4 -mr-4 text-base font-medium text-gray-600 cursor-pointer whitespace-nowrap hover:text-gray-900"
                  >
                    Se déconnecter
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <Drawer />

        <CTA />
      </div>
    </>
  )
}

export const NavigationContainer: React.FC<ContainerProps> = props => (
  <NavigationWrapper {...props} />
)

export const Navigation = connector(NavigationContainer)
