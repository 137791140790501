import React, { ReactElement } from "react"

type Props = {
  to?: string
  title: string
  icon: ReactElement
  onClick: Function
  color?: string
}

export const Item: React.FC<Props> = props => (
  <span
    onClick={() => props.onClick()}
    className="flex items-center p-3 py-4 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg cursor-pointer hover:bg-gray-100"
  >
    <div className={`flex-shrink-0 flex items-center text-green-600`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6"
      >
        {props.icon}
      </svg>
    </div>
    <div className="text-base font-medium leading-6 text-gray-800">
      {props.title}
    </div>
  </span>
)

export const OutboundLink: React.FC<Omit<Props, "onClick">> = props => (
  <a
    href={props.to}
    className="flex items-center p-3 py-4 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg cursor-pointer hover:bg-gray-100"
  >
    <div className={`flex-shrink-0 flex items-center text-green-600`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6"
      >
        {props.icon}
      </svg>
    </div>
    <div className="text-base font-medium leading-6 text-gray-800">
      {props.title}
    </div>
  </a>
)
