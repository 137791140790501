import React from "react"
import { Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { connector, ContainerProps } from "./containers/Drawer.container"
import * as Components from "./components"
import { UserEntity } from "../../entities/UserEntity"

import logo from "../../assets/logo/logo.svg"

export type DrawerProps = {
  onClose?: Function
  onGo?: Function
  onLogout?: Function
  isAuthenticated: boolean
  user: UserEntity
}

export const DrawerWrapper: React.FC<DrawerProps> = ({
  isAuthenticated,
  user,
  onClose = () => false,
  onGo = () => false,
  onLogout = () => false,
}) => {
  const location = useLocation()

  const isOpen = location.href?.includes("menu=open") || false

  return (
    <Transition
      show={isOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform lg:hidden">
        <div
          className="absolute top-0 left-0 w-full min-h-screen bg-transparent"
          onClick={() => onClose()}
        />
        <div className="relative rounded-lg shadow-lg">
          <div className="bg-white divide-y-2 rounded-lg shadow-xs divide-gray-50">
            <div className="px-5 pt-5 pb-6 space-y-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="w-auto h-8"
                    src={logo}
                    alt="Le temple du haïku"
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => onClose()}
                    className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                  >
                    <svg
                      className="w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <nav className="grid gap-6">
                  <Components.Item
                    title="Lire les haïkus des utilisateurs"
                    to="/"
                    onClick={() => onGo("/")}
                    icon={
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    }
                  />
                  <Components.Item
                    title="Écrire un haïku"
                    to="/ecrire/"
                    onClick={() => onGo("/ecrire/")}
                    icon={
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    }
                  />

                  <Components.OutboundLink
                    title="Recueil d'anciens haïkus"
                    to="https://www.temple-du-haiku.fr/recueils/"
                    icon={
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                      />
                    }
                  />
                  
                  <Components.OutboundLink
                    title="Trouver de l'inspiration"
                    to="https://www.temple-du-haiku.fr/exemples-de-haiku/"
                    icon={
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                      />
                    }
                  />
                  {isAuthenticated && user && (
                    <Components.Item
                      title="Ma page"
                      to={`/auteurs/${user.id}/`}
                      onClick={() => onGo(`/auteurs/${user.id}/`)}
                      icon={
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                        />
                      }
                    />
                  )}
                </nav>
              </div>
            </div>

            <div className="px-5 py-6 space-y-6">
              {/* <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Components.Link to="/">Accueil</Components.Link>
                <Components.Link to="/blog/">Blog</Components.Link>
                <Components.Link to="/nous-contacter/">Nous contacter</Components.Link>
              </div> */}

              {!isAuthenticated && (
                <div className="space-y-6">
                  <span className="flex w-full rounded-md shadow-sm">
                    <span
                      onClick={() => onGo("/creer-un-compte/")}
                      className="flex items-center justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-800 border border-transparent rounded-md cursor-pointer hover:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900"
                    >
                      Créer un compte
                    </span>
                  </span>
                  <p className="text-base font-medium leading-6 text-center text-gray-500">
                    Déjà utilisateur ?{" "}
                    <span
                      onClick={() => onGo("/se-connecter/")}
                      className="ml-2 font-bold text-green-600 underline transition duration-150 ease-in-out cursor-pointer hover:text-green-800"
                    >
                      Se connecter
                    </span>
                  </p>
                </div>
              )}
              {isAuthenticated && (
                <div className="space-y-6">
                  <span className="flex w-full rounded-md shadow-sm">
                    <span
                      onClick={() => onLogout()}
                      className="flex items-center justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-gray-800 border border-transparent rounded-md hover:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900"
                    >
                      Se déconnecter
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export const DrawerContainer: React.FC<ContainerProps> = props => (
  <DrawerWrapper {...props} />
)

export const Drawer = connector(DrawerContainer)
